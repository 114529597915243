<template>
    <div
        v-if="isBlindTester"
        class="profile-photo-layout-container blind"
        :class="{
            accepted: accepted,
            rejected: rejected,
        }"
    >
        <div class="profile-photo-layout">
            <div class="img-wrapper flex-wrap" @click="openProfile">
                <div class="overlay" v-if="rejected" />
                <img class="src-img" v-lazy="require('@/assets/images/logo_symbol.png')" />
            </div>
            <div class="flex profile-wrapper" @click="openProfile">
                <div
                    v-if="accepted || rejected"
                    class="response"
                    :class="{ 'm-b-8 f-medium': accepted, 'm-b-8': rejected }"
                    v-html="response"
                />
                <div v-if="!rejected" class="name-age" v-html="jobAge" />
                <div v-if="!rejected" class="region-job" :class="{ 'm-b-8': !accepted }" v-html="region" />
                <div v-else class="region-job m-b-8" v-html="`${jobAge}, ${region}`" />
                <div class="btns">
                    <template v-if="!(rejected || accepted)">
                        <button @click.stop="openProfile" class="btn btn-accept" v-html="'프로필 확인하기'" />
                    </template>
                    <button
                        v-if="rejected"
                        @click.stop="openProfile"
                        class="btn btn-undo"
                        v-html="'프로필 다시 보기'"
                    />
                </div>
            </div>
        </div>
    </div>
    <div
        v-else
        class="profile-photo-layout-container"
        :class="{
            accepted: accepted,
            rejected: rejected,
        }"
    >
        <div class="profile-photo-layout">
            <div class="img-wrapper flex-wrap" @click="openProfile">
                <div class="overlay" v-if="rejected" />
                <img class="src-img" v-lazy="images[0]" />
            </div>
            <div class="flex profile-wrapper" @click="openProfile">
                <div
                    v-if="accepted || rejected"
                    class="response"
                    :class="{ 'm-b-8 f-medium': accepted, 'm-b-12': rejected }"
                    v-html="response"
                />
                <div v-if="!rejected" class="name-age" v-html="jobAge" />
                <div v-if="!rejected" class="region-job" :class="{ 'm-b-8': !accepted }" v-html="region" />
                <div class="btns">
                    <template v-if="!(rejected || accepted)">
                        <button @click.stop="reject" class="btn btn-pass" v-html="$translate('PASS')" />
                        <button @click.stop="onClickAccept" class="btn btn-accept" v-html="acceptCTA" />
                    </template>
                    <button v-if="rejected" @click.stop="undo" class="btn btn-undo" v-html="$translate('UNDO')" />
                </div>
            </div>
        </div>

        <div v-if="premiumInfo" class="premium-comment">
            <div class="title">
                <img class="m-r-4 m-r-4" :src="require('@/assets/images/premium/premium_gold_crown.svg')" />
                <span>주선자 코멘트</span>
            </div>
            <div class="content" v-html="premiumInfo.info.agent_intro" />
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'ProfilePhotoLayout',
    props: ['message'],
    data: () => ({
        acceptStatus: null,
        timer: null,
    }),
    watch: {
        content: {
            handler() {
                this.init()
            },
            deep: true,
        },
    },
    created() {},
    computed: {
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        images() {
            if (this.content.show_photo === false) return this.$blankProfile

            return this.content.urls
        },
        numImages() {
            return (this.images || []).length
        },
        jobAge() {
            // const { job, birthday } = this.content
            const { birthday } = this.content
            // return `${job || '-'}, ${this.$options.filters.asAge(birthday)}`
            return `${this.$options.filters.asAge(birthday)}세`
        },
        region() {
            if (this.isBlindTester) {
                const { region } = this.content
                return `${this.$options.filters.noDetail(region).split(' ')[0] || '-'}`
            } else {
                const { region } = this.content
                return `${this.$options.filters.noDetail(region) || '-'}`
            }
        },
        response() {
            if (this.rejected) return '패스했습니다'
            if (this.accepted) {
                return '소개 받을게요'
            }

            return ''
        },
        chat() {
            return this.$store.getters.chat
        },
        agentId() {
            return this.chat.agent_id
        },
        opened() {
            return !!this.content.opened
        },
        accepted() {
            return this.acceptStatus === 'accepted'
        },
        rejected() {
            return this.acceptStatus === 'rejected'
        },
        acceptCTA() {
            return this.$translate('INTRODUCE_ME')
        },
        premiumInfo() {
            if (this.$isInstantChat(this.chat)) {
                const premiumUsers = this.$store.getters.instantRoomPremiumUsers || []
                if (!premiumUsers.length) return false

                return premiumUsers.find(p => p.id === this.content.source_id) || ''
            } else return false
        },
        isBlindTester() {
            return (this.me.blind_test && this.me.gender === 1) || this.me.products.find(p => p.product_id === 60)
        },
    },
    methods: {
        init() {
            this.acceptStatus = this.content.accept_status
        },
        throttle(handler, delay) {
            if (!this.timer) {
                this.timer = setTimeout(() => {
                    this.timer = null
                }, delay)

                handler()
            }
        },
        async openProfile() {
            if (this.content.show_photo === false) {
                this.$toast.error('탈퇴 혹은 휴면했거나 활동이 정지된 회원입니다.')
                return
            }
            const photos = () => {
                let photos = []
                if (this.content.url) {
                    photos.push(this.content.url)
                }

                if (this.content.urls) {
                    photos = photos.concat(this.content.urls)
                }

                return photos.map(p => ({ url: p }))
            }

            chatService.openProfile(this.chat.id, this.message.id)
            const content = this.$mustParse(this.message.content)
            content.opened = true
            this.message.content = JSON.stringify(content)

            if (this.isBlindTester) {
                this.$stackRouter.push({
                    // name: 'UserDetailPage',
                    name: 'UserBlindProfilePage',
                    props: {
                        userId: this.content.source_id,
                        photos: photos(),
                        from: 'ProfilePhoto',
                        profilePhotoMessage: this.message,
                    },
                })
            } else {
                this.$stackRouter.push({
                    name: 'UserDetailPage',
                    // name: 'UserBlindProfilePage',

                    props: {
                        userId: this.content.source_id,
                        photos: photos(),
                        from: 'ProfilePhoto',
                        profilePhotoMessage: this.message,
                    },
                })
            }
        },
        async updateMessage(updateStatus) {
            if (this.content.show_photo === false) {
                this.$toast.error('탈퇴 혹은 휴면했거나 활동이 정지된 회원입니다.')
                return
            }

            try {
                await chatService.acceptProfile(this.chat.id, this.message.id, updateStatus)
                this.updateAccepted(updateStatus)
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        reject() {
            this.updateMessage('rejected')
        },

        async onClickAccept() {
            const alert = await this.$modal.basic({
                body: '소개 받으시겠어요?',
                buttons: [
                    {
                        label: '아니오',
                        class: 'btn-default',
                    },
                    {
                        label: '네',
                        class: 'btn-primary',
                    },
                ],
            })
            if (alert) {
                this.throttle(this.accept, 1000)
            }
        },
        accept() {
            // const alreadyReadHowToUseFeat = localStorage.getItem('how-to-use')
            // if (!alreadyReadHowToUseFeat) {
            //     this.$modal.custom({
            //         component: 'ModalHowToUseFeats',
            //         options: {
            //             preventCloseOnMousedownMask: true,
            //         },
            //     })
            //     localStorage.setItem('how-to-use', true)
            // }
            this.updateMessage('accepted')
        },
        undo() {
            this.updateMessage('undo')
        },
        updateAccepted(result) {
            const message = this.$store.getters.chat.$$messages.find(message => message.id === this.message.id)
            this.content.accept_status = result
            message.content = JSON.stringify(this.content)
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.profile-photo-layout-container {
    width: calc(100vw - 120px);
    max-width: 260px;
    padding: 6px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06), 0 2px 6px 0 rgba(138, 116, 255, 0.05);

    &.accepted {
        box-sizing: border-box;
        border: solid 1px $blue-primary;
        .response {
            font-size: 14px;
            color: $blue-primary;
            @include f-medium;
        }

        .name-age {
            font-size: 12px;
            margin: 0;
            color: $grey-08;
            @include f-regular;
        }

        &.isPremium {
            border: solid 1px $yellow-premium;

            .response {
                color: $yellow-premium;
            }
        }
    }

    &.rejected {
        .response {
            font-size: 14px;
            color: $grey-08;
        }
    }
}

.premium-comment {
    border-top: 1px solid $grey-02;
    margin-top: 6px;
    padding-top: 6px;

    .title {
        color: $yellow-premium;
        font-size: 13px;
        @include f-medium;
    }
    .content {
        font-size: 15px;
        color: black;
        letter-spacing: -0.2px;
        @include f-regular;
    }
}

.profile-photo-layout {
    display: flex;
    align-items: center;

    .img-wrapper {
        position: relative;
        width: 80px;
        height: 80px;
        margin-right: 12px;

        &.blurred::before {
            position: absolute;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(3px);
            pointer-events: none;
            border-radius: 8px;
            content: '';
        }

        .overlay {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            &:not(.blurred-overlay) {
                background: rgba(255, 255, 255, 0.6);
            }
        }
    }

    .src-img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: solid 1px $grey-02;
    }

    .profile-wrapper {
        width: calc(100% - 92px);
    }

    .name-age {
        margin-bottom: 4px;
        color: black;
        font-size: 14px;
        @include f-medium;
    }

    .region-job {
        color: $grey-08;
        font-size: 12px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
            color: $grey-04;
        }
    }

    .btns {
        @include flex-row;

        .btn {
            height: 32px;
            padding: 0;
            margin: 0;
            font-size: 13px;
            border-radius: 6px;
            box-shadow: none;
            @include f-regular;
            @include center;
        }

        .btn.btn-profile {
            width: 104px;
        }

        .btn.btn-pass {
            flex: 1 1 auto;
            margin-right: 6px;
            background-color: $grey-02;
            color: $grey-09;
        }

        .btn.btn-accept {
            flex: 1 1 auto;
            background: $blue-primary;
            color: white;
            @include f-medium;
        }

        .btn.btn-undo {
            // width: 88px;
            width: 100%;
            border: solid 1px $gray-divider;
            color: $grey-09;
        }
    }
}
</style>
