<template>
    <div class="photo-wrapper" @click.stop="onClickImage">
        <img v-if="imageUrl" class="img-cover flex-wrap" v-lazy="imageUrl" />
        <div v-if="user.name" class="user-profile">
            <div class="detail">
                <div class="f-medium f-14 m-b-4" v-html="nicknameAge" />
                <div class="region" v-html="region" />
            </div>
            <div class="button-container">
                <div class="img-container">
                    <!-- <img
                        class="profile-btn"
                        @click.stop="onClickPhotoStatus"
                        :src="require(`@/assets/images/chat/check-btn-${phCheck ? 'active' : 'inactive'}.png`)"
                        alt=""
                    /> -->
                </div>
                <div class="img-container">
                    <img
                        class="profile-btn"
                        @click.stop="$emit('seeProfile')"
                        :src="require('@/assets/images/chat/profile-btn.png')"
                        alt=""
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'PhotoLayout',
    props: ['user', 'image', 'message', 'isBlindTester'],
    data: () => ({
        phCheck: false,
    }),
    mounted() {
        this.phCheck = (this.user || {}).ph_check
    },
    computed: {
        nicknameAge() {
            // return `${this.user.nickname || '-'}, ${this.$options.filters.asAge(this.user.birthday)}`
            return `${this.$options.filters.asAge(this.user.birthday)}세`
        },
        region() {
            if (this.isBlindTester) {
                return this.$options.filters.noDetail(this.user.region).split(' ')[0] || '-'
            } else {
                return this.$options.filters.noDetail(this.user.region) || '-'
            }
        },
        imageUrl() {
            return this.user.show_photo === false ? this.$blankProfile : this.image
        },
    },
    methods: {
        onClickImage() {
            if (this.user.show_photo === false) {
                this.$toast.error('탈퇴 혹은 휴면했거나 활동이 정지된 회원입니다.')
                return
            }
            this.$modal.images({
                images: [this.image.replace('/medium/', '/large/')],
            })
        },
        toastMsg(res) {
            if (res === 'accept') return '상대가 수락했습니다.'
            if (res === 'passed') return '상대가 패스했습니다.'
            if (res === 'checked') return '상대가 프로필을 확인했습니다.'
            if (res === 'not_checked') return '상대가 아직 확인하지 않았습니다.'
        },
        async onClickPhotoStatus() {
            const { chat_id: chatId, id } = this.message

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `ChatDetail_Click_SeeStatus`,
                },
            })

            if (this.phCheck) {
                try {
                    const { res } = await chatService.checkPhotoHistory(chatId, id)
                    this.$toast.success(this.toastMsg(res))
                } catch (e) {
                    this.$toast.error('처리 중 오류가 발생했습니다')
                }
            } else {
                const idx = await this.$modal.basic({
                    title: '상대방의 수락/거절 상태 보기',
                    body: '10 포인트가 차감됩니다',
                    buttons: [
                        {
                            label: '아니오',
                            class: 'btn-default',
                        },
                        {
                            label: '네',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (!idx) return

                // 과금 진행하는 경우
                try {
                    const { res } = await chatService.hasPaidPhotoHistory(chatId, id)
                    if (res === 'no_point') {
                        const idx = await this.$modal.basic({
                            body: '포인트가 부족합니다. 친구를 초대하고 포인트를 얻어보세요.',
                            buttons: [
                                {
                                    label: '아니오',
                                    class: 'btn-default',
                                },
                                {
                                    label: '포인트 얻기',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        if (idx) this.$router.push({ name: 'InvitationFriendPage' })
                    } else {
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: `ChatDetail_Buy_SeeStatus`,
                            },
                        })

                        const { res } = await chatService.checkPhotoHistory(chatId, id)
                        this.phCheck = true
                        this.$toast.success(this.toastMsg(res))
                        this.$store.dispatch('loadBadges')
                    }
                } catch (e) {
                    this.$toast.error('처리 중 오류가 발생했습니다')
                }
            }
        },
    },
}
</script>
